@media (min-width: 1200px) and (max-width: 1399.98px) {
  header {
    height: 650px;
  }

  header nav a {
    font-size: 15px;
  }

  /* Banner */
  #banner {
    bottom: 83px;
  }

  #banner h1 {
    font-size: 30px;
    line-height: 42px;
    padding-bottom: 35px;
  }

  #banner p {
    font-size: 19px;
  }

  /* Mixed */
  #pricing .price_heading h2,
  #internet-plans .plans-container h2,
  #remote_area h3 {
    font-size: 32px;
  }

  /* ===================
  Enterprse Page
  ====================== */
  header.enterprse #banner p {
    padding-top: 10px;
  }

  #ltp-plans .ltp_plans {
    padding: 30px 15px 0 15px;
  }

  #ltp-plans .ltp_heading h2 {
    font-size: 32px;
  }

  /* =================
  Blog Page
  =================== */
  #blog .blog-preview {
    gap: 8px;
    padding: 15px 0;
  }

  #blog .blog-content .blog-text h3 {
    font-size: 20px;
  }

  #blog .blog-content .blog-text p {
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  header {
    height: 550px;
  }

  header nav a {
    font-size: 14px;
  }

  /* Banner */
  #banner {
    bottom: 50px;
  }

  #banner h1 {
    font-size: 24px;
    line-height: 38px;
    padding-bottom: 30px;
  }

  #banner p {
    font-size: 17px;
    line-height: 28px;
  }

  /* Price Plan */
  .price_table table {
    width: 100%;
  }

  /* Mixed */
  #pricing .price_heading h2,
  #internet-plans .plans-container h2,
  #remote_area h3 {
    font-size: 28px;
  }

  /* Internet Plan */
  #internet-plans .plans_head {
    padding: 120px 0;
  }

  #internet-plans .plans_head h6 {
    font-size: 22px;
    line-height: 32px;
  }

  #internet-plans .plans_head h4 {
    font-size: 38px;
    padding-top: 55px;
  }

  /* For remote areas */
  #remote_area {
    padding: 100px 0;
  }

  #remote_area h3 {
    padding-bottom: 50px;
  }

  /* ===================
  Enterprse Page
  ====================== */
  header.enterprse {
    height: 600px;
  }

  header.enterprse #banner p {
    padding-top: 8px;
  }

  #ltp-plans .ltp_plans {
    padding: 25px 5px 0 5px;
  }

  #ltp-plans .ltp_heading h2 {
    font-size: 28px;
  }

  #ltp-plans .ltp_plans h3 {
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 10px;
  }

  #ltp-plans .ltp_plans p {
    font-size: 15px;
    line-height: 26px;
  }

  #ltp-plans .ltp_plans .ltp_plans_btn a {
    padding: 18px 20px;
    font-size: 18px;
    text-transform: uppercase;
    box-shadow: -1px 2px 11px 0px rgba(0, 0, 0, 0.35);
    transition: all 0.3s linear;
  }

  #ltp-plans.lte-data-plans .ltp_plans h4 {
    font-size: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  button a {
    border-radius: 10px;
    padding: 20px 42px;
    font-size: 18px;
  }

  header {
    height: 450px;
  }

  header nav a {
    font-size: 13px;
  }

  /* Banner */
  #banner {
    bottom: 0;
    left: 0;
    transform: none;
    color: var(--prime-color);
    padding-bottom: 50px;
    width: 100%;
  }

  #banner h1 {
    font-size: 24px;
    line-height: 38px;
    padding-bottom: 30px;
  }

  #banner p {
    font-size: 17px;
    line-height: 28px;
  }

  /* Ads */
  #ads .containt {
    border-radius: 10px;
    gap: 10px;
    padding: 12px 25px;
    margin-bottom: 15px;
  }

  #ads .containt h4 {
    font-size: 14px;
    padding-bottom: 7px;
  }

  #ads .containt p {
    font-size: 14px;
  }

  /* Mixed */
  #pricing .price_heading h2,
  #internet-plans .plans-container h2,
  #remote_area h3 {
    font-size: 20px;
  }

  /* Price Plan */
  .price_table table {
    width: 100%;
  }

  #pricing .price_heading h2 {
    padding-bottom: 30px;
  }

  .price_table table th {
    font-size: 17px;
    line-height: 24px;
    padding: 18px 0;
  }

  .price_table table tr td {
    padding: 18px 0;
  }

  #pricing .price-buttons {
    padding-top: 40px;
  }

  #pricing .price-buttons button {
    margin: 0;
  }

  #pricing .price-buttons button a.extra_width {
    padding: 20px 42px;
  }

  /* /* Internet Plans */
  #internet-plans .plans-container h2 {
    padding-bottom: 50px;
  }

  #internet-plans .plans-container table th {
    font-size: 16px;
    line-height: 24px;
    padding: 18px 0;
  }

  #internet-plans .plans-container table tr td {
    padding: 17px 0;
  }

  #internet-plans .plans-buttons {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  #internet-plans .plans-buttons button {
    margin: 0;
  }

  #internet-plans .plans-buttons button a.extra_width {
    padding: 20px 42px;
  }

  /* For remote areas */
  #remote_area {
    padding: 70px 0;
  }

  #remote_area h3 {
    padding-bottom: 25px;
  }

  #remote_area p {
    font-size: 16px;
    line-height: 25px;
  }

  /* Internet Satellite Price Plans */
  #satellite-plans .set_plans h5 {
    font-size: 20px;
  }

  #satellite-plans .set_plans_btn {
    padding-top: 30px;
  }

  /* Get In Touch */
  #get-touch {
    padding: 50px 0;
  }

  #get-touch .touch-text h6 {
    font-size: 25px;
    padding-bottom: 25px;
  }

  #get-touch .touch-text p {
    font-size: 20px;
    line-height: 33px;
  }

  /* Footer */
  footer {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  footer .footer_icon {
    padding-bottom: 20px;
  }

  /* ===================
  Enterprse Page
  ====================== */
  header.enterprse {
    height: 520px;
  }

  header.enterprse #banner p {
    padding-top: 8px;
  }

  #ltp-plans .ltp_plans {
    padding: 20px 5px 0 5px;
  }

  #ltp-plans .ltp_heading h2 {
    font-size: 20px;
  }

  #ltp-plans .ltp_plans h3 {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 10px;
  }

  #ltp-plans .ltp_plans p {
    font-size: 13px;
    line-height: 22px;
  }

  #ltp-plans .ltp_plans .ltp_plans_btn {
    padding-top: 15px;
    margin-bottom: -25px;
  }

  #ltp-plans .ltp_plans .ltp_plans_btn a {
    padding: 12px 18px;
    font-size: 11px;
    text-transform: uppercase;
    box-shadow: -1px 2px 11px 0px rgba(0, 0, 0, 0.35);
    transition: all 0.3s linear;
  }

  #ltp-plans .ltp_plans .ltp_plans_btn a i {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-left: 2px;
  }

  .lte_footer p {
    font-size: 14px;
    padding: 18px 0;
  }

  #ltp-plans.lte-data-plans .ltp_plans {
    padding: 15px 0 0 0;
  }

  #ltp-plans.lte-data-plans .ltp_plans h5 {
    font-size: 24px;
  }

  #ltp-plans.lte-data-plans .ltp_plans h6 {
    font-size: 18px;
  }

  #ltp-plans.lte-data-plans .ltp_plans h4 {
    font-size: 40px;
  }

  header.privacy-header {
    height: 400px;
  }

  #banner.psivacy-banner {
    bottom: 70px;
  }

  /* =================
  Blog Page
  =================== */
  #blog .blog-preview {
    gap: 8px;
    padding: 15px 0;
  }

  #blog .blog-content .blog-text h3 {
    font-size: 20px;
  }

  #blog .blog-content .blog-text p {
    font-size: 13px;
  }

  /* =================
  About Us Page
  =================== */
  #about-us {
    padding: 80px 0;
  }

  #about-us .about .about-text {
    width: 65%;
  }

  #about-us .about .about-img {
    width: 35%;
  }

  #about-us .about .about-text h6 {
    font-size: 22px;
    padding-bottom: 15px;
  }

  #about-us .about .about-text p {
    font-size: 16px;
    line-height: 27px;
  }

  /* Partners Css Start */
  #partners {
    padding-bottom: 80px;
  }

  #partners h2 {
    font-size: 30px;
  }

  #partners .partners-container .partners-item {
    padding: 20px 0 70px 0;
  }

  #partners .partners-container .slider_dots li button {
    width: 20px;
  }
}

@media (max-width: 767.98px) {
  .desktop-nav {
    display: none;
    visibility: hidden;
  }

  button {
    width: 100%;
  }

  button a {
    border-radius: 10px;
    padding: 12px;
    font-size: 16px;
  }

  #mobile-nav button {
    width: fit-content;
  }

  header {
    height: 390px;
  }

  header nav a {
    font-size: 13px;
  }

  /* Banner */
  #banner {
    width: 100%;
    bottom: 0;
    left: 0;
    transform: none;
    color: var(--prime-color);
    padding-bottom: 50px;
  }

  #banner h1 {
    font-size: 24px;
    line-height: 38px;
    padding-bottom: 15px;
  }

  #banner p {
    font-size: 15px;
    line-height: 24px;
  }

  /* Ads */
  #ads .containt {
    border-radius: 10px;
    gap: 10px;
    padding: 12px 25px;
    margin-bottom: 15px;
  }

  #ads .containt h4 {
    font-size: 14px;
    padding-bottom: 7px;
  }

  #ads .containt p {
    font-size: 14px;
  }

  /* Mixed */
  #pricing .price_heading h2,
  #internet-plans .plans-container h2,
  #remote_area h3 {
    font-size: 18px;
    line-height: 30px;
  }

  /* Price Plan */
  .price_table table {
    width: 100%;
  }

  #pricing .price_heading h2 {
    padding-bottom: 30px;
  }

  .price_table table th {
    font-size: 15px;
    line-height: 24px;
    padding: 18px 0;
  }

  .price_table table tr td {
    padding: 15px 0;
    font-size: 14px;
  }

  #pricing .price-buttons {
    padding-top: 20px;
  }

  #pricing .price-buttons button {
    margin: 0;
    margin-top: 15px;
  }

  #pricing .price-buttons button a.extra_width {
    padding: 12px;
  }

  /* /* Internet Plans */
  #internet-plans .plans_head {
    padding: 70px 0;
    text-align: center;
  }

  #internet-plans .plans_head h6 {
    font-size: 16px;
    line-height: 25px;
  }

  #internet-plans .plans_head h4 {
    font-size: 30px;
    padding-top: 15px;
  }

  #internet-plans .plans-container h2 {
    padding-bottom: 50px;
  }

  #internet-plans .plans-container table th {
    font-size: 12px;
    line-height: 24px;
    padding: 10px 0;
  }

  #internet-plans .plans-container table tr td {
    padding: 12px 0;
    font-size: 14px;
  }

  #internet-plans .plans-buttons {
    padding-top: 10px;
    padding-bottom: 25px;
  }

  #internet-plans .plans-buttons button {
    margin: 0;
    margin-top: 15px;
  }

  #internet-plans .plans-buttons button a.extra_width {
    padding: 12px;
  }

  /* For remote areas */
  #remote_area {
    padding: 50px 0;
  }

  #remote_area h3 {
    padding-bottom: 15px;
  }

  #remote_area p {
    font-size: 13px;
    line-height: 25px;
  }

  /* Internet Satellite Price Plans */
  #satellite-plans .set_plans {
    grid-template-columns: 1fr;
  }

  #satellite-plans .set_plans h5 {
    font-size: 18px;
  }

  #satellite-plans .set_plans_btn {
    padding-top: 30px;
  }

  /* Get In Touch */
  #get-touch {
    padding: 50px 0;
    text-align: center;
  }

  #get-touch .touch-text h6 {
    font-size: 22px;
    padding-bottom: 10px;
  }

  #get-touch .touch-text p {
    font-size: 18px;
    line-height: 25px;
  }

  /* Footer */
  footer {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  footer .footer_icon {
    padding-bottom: 20px;
  }

  footer .footer_map iframe {
    width: 100%;
    height: 120px;
    margin-top: 20px;
  }

  /* ===================
  Enterprse Page
  ====================== */
  header.enterprse {
    height: 350px;
  }

  header.enterprse #banner p {
    padding-top: 8px;
  }

  #ltp-plans .ltp_plans {
    padding: 20px 5px 0 5px;
  }

  #ltp-plans .ltp_heading h2 {
    font-size: 18px;
    padding-bottom: 20px;
  }

  #ltp-plans .ltp_plans h3 {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 10px;
    line-height: 30px;
  }

  #ltp-plans .ltp_plans p {
    font-size: 13px;
    line-height: 22px;
  }

  #ltp-plans .ltp_plans .ltp_plans_btn {
    padding-top: 15px;
    margin-bottom: -25px;
  }

  #ltp-plans .ltp_plans .ltp_plans_btn a {
    padding: 12px 18px;
    font-size: 11px;
    text-transform: uppercase;
    box-shadow: -1px 2px 11px 0px rgba(0, 0, 0, 0.35);
    transition: all 0.3s linear;
  }

  #ltp-plans .ltp_plans .ltp_plans_btn a i {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-left: 2px;
  }

  .lte_footer p {
    font-size: 12px;
    padding: 18px 0;
  }

  #ltp-plans.lte-data-plans .ltp_plans {
    padding: 15px 0 0 0;
  }

  #ltp-plans.lte-data-plans .ltp_plans h5 {
    font-size: 24px;
  }

  #ltp-plans.lte-data-plans .ltp_plans h6 {
    font-size: 18px;
  }

  #ltp-plans.lte-data-plans .ltp_plans h4 {
    font-size: 40px;
  }

  #satellite-plans #pricing .price_table table {
    font-size: 12px;
  }

  #view h4 {
    font-size: 25px;
    text-align: center;
  }

  #price-table {
    padding-top: 10px;
  }

  /* =================
  Privacy Policy Page
  =================== */
  header.privacy-header {
    height: 220px;
  }

  header.privacy-header #banner h1 {
    font-size: 18px;
  }

  #banner.psivacy-banner {
    bottom: 40px;
  }

  header.privacy-header .overlay-btn {
    width: 270px;
    clip-path: polygon(6% 0%, 94% 0%, 100% 100%, 0% 100%);
  }

  header.privacy-header .overlay-btn a {
    font-size: 12px;
  }

  #privacy-containt {
    padding: 50px 0;
  }

  #privacy-containt .container {
    gap: 20px;
  }

  #privacy-containt h5 {
    font-size: 24px;
    padding-bottom: 15px;
  }

  #privacy-containt h6 {
    font-size: 18px;
    padding-bottom: 10px;
  }

  #privacy-containt p,
  #privacy-containt .privacy-item ul li {
    font-size: 15px;
    line-height: 25px;
  }

  /* =================
  Blog Page
  =================== */
  #blog {
    padding: 50px 0;
  }

  #blog .blog-preview {
    gap: 10px;
    padding: 12px 0;
  }

  #blog .blog-content .blog-text h3 {
    font-size: 18px;
  }

  #blog .blog-content .blog-text p {
    font-size: 13px;
  }

  #blog .blog-content .blog-text .blog-btn a {
    padding: 7px 18px;
    border-radius: 5px;
    margin-top: 15px;
    font-size: 14px;
  }

  /* =================
  Blog Details Page
  =================== */
  #blog-details {
    padding-top: 50px;
  }

  #blog-details .blog-details-head h4 {
    font-size: 22px;
    padding-top: 7px;
    padding-bottom: 22px;
  }

  #blog-details .blog-details-preview {
    flex-wrap: wrap;
  }

  #blog-details p {
    font-size: 15px;
    line-height: 26px;
  }

  #blog-details p span {
    font-size: 20px;
  }

  #blog-comment {
    padding-bottom: 50px;
  }

  #blog-comment .comment-heading h5 {
    font-size: 18px;
  }

  .comments .com-item {
    gap: 12px;
  }

  .comments .com-item i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
  }

  .comments .com-item h6 {
    font-size: 15px;
  }

  .comments .com-item p {
    font-size: 13px;
  }

  .comment-box input {
    font-size: 14px;
    padding: 10px;
  }

  .comment-box textarea {
    font-size: 14px;
    width: 100%;
    height: 80px;
    padding: 10px;
  }

  .comment-box button a {
    display: block;
    font-size: 16px;
  }

  /* =================
  About Us Page
  =================== */
  #about-us {
    padding: 50px 0;
  }

  #about-us .about {
    flex-direction: column-reverse;
    text-align: center;
  }

  #about-us .about .about-text {
    width: 100%;
  }

  #about-us .about .about-img {
    width: 90%;
  }

  #about-us .about .about-text h6 {
    font-size: 20px;
    padding-bottom: 15px;
  }

  #about-us .about .about-text p {
    font-size: 15px;
    line-height: 25px;
    max-width: 500px;
  }

  /* Partners Css Start */
  #partners {
    padding-bottom: 50px;
  }

  #partners h2 {
    font-size: 25px;
    padding-bottom: 30px;
  }

  #partners .partner-img {
    width: 97px;
    height: 97px;
  }

  #partners .partner-img img {
    width: 60px;
    object-fit: cover;
  }

  #partners .partners-container .partners-item {
    padding: 20px 0 50px 0;
  }

  #partners .partners-container .slider_dots li {
    margin: 0 2px;
  }

  #partners .partners-container .slider_dots li button {
    width: 10px;
    height: 2px;
  }
}
