/* You can add global styles to this file, and also import other style files */
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

.bold {
    font-weight: 600;
}

.form-control {
    height: 50px;
}

.router-link-active {
    border-bottom: 1px solid white;
}
