/* Google Font- "Poppins" */
@import url("https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600&display=swap");

/* ||Common Css Start|| */
* {
  font-family: "Mitr", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
  padding: 0;
}

a,
a:hover {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul li {
  display: inline-block;
  text-decoration: none;
}

:root {
  /*color*/
  --prime-color: #fff;
  --highlight-color: #3A73F6;
  /*font*/
  --regular-font-color: #4a4a4a;
  --secondary-font-color: #707070;
  /*transition*/
  --first-transition: all 0.3s linear;
  --slow-transition: all 0.4s linear;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
}

button a {
  border-radius: 17px;
  display: block;
  color: var(--prime-color);
  padding: 22px 48px;
  font-size: 19px;
  font-weight: 500;
  transition: all 0.3s linear;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: var(--highlight-color);
}

button a::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #28cdff 0%, #356ce2 100%);
  z-index: -1;
  transition: all 0.3s linear;
}

button a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background: linear-gradient(to top, #28cdff 0%, #356ce2 100%);
  z-index: -1;
  transition: all 0.3s linear;
}

button a:hover::after {
  height: 0;
}

button a:hover::before {
  height: 100%;
}

button a:hover {
  color: var(--prime-color);
  box-shadow: -3px 4px 27px 0px rgba(0, 0, 0, 0.35);
}

/* ||Common Css End|| */

/* Header Css Start */
#mobile-nav .navbar {
  background: var(--highlight-color);
  padding: 10px 0;
}

#mobile-nav .navbar .navbar-brand img {
  width: 65px;
}

#mobile-nav .navbar ul {
  padding-top: 6px;
  margin-top: 10px;
  border-top: 1px solid var(--prime-color);
}

#mobile-nav .navbar ul li a {
  color: var(--prime-color);
  font-size: 18px;
  font-weight: 300;
  transition: var(--first-transition);
}

#mobile-nav .navbar button {
  color: var(--prime-color);
}

header {
  background: url("/assets/images/business-internet-connectivity-solutions-wireless-air-and-satellite.png");
  height: 770px;
  background-size: cover !important;
  background-position: bottom !important;
  position: relative;
  z-index: 1;
}

header::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 50%);
  z-index: -1;
}

header nav {
  padding-top: 33px;
}

header nav .logo img {
  width: 110px;
}

header nav a {
  color: var(--prime-color);
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 2px;
  transition: var(--first-transition);
}

header nav a:hover {
  color: var(--highlight-color);
}

#banner {
  position: absolute;
  bottom: 83px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--prime-color);
}

#banner h1 {
  font-size: 35px;
  font-weight: 600;
  line-height: 45px;
  text-transform: uppercase;
  padding-bottom: 43px;
}

#banner p {
  font-size: 20px;
  font-weight: 200;
  line-height: 30px;
}

/* ||Header Css End|| */

/* ADS Css Start */
#ads {
  padding: 50px 0;
  background: #f4f4f4;
  border-bottom: 1px solid var(--secondary-font-color);
}

#ads .containt {
  border: 1px solid var(--secondary-font-color);
  border-radius: 13px;
  gap: 20px;
  padding: 18px 32px;
  margin-bottom: 25px;
  height: 150px;
  box-shadow: -1px 2px 11px 0px rgba(0, 0, 0, 0);
  transition: all 0.3s linear;
}

#ads .containt:hover {
  box-shadow: -1px 2px 11px 0px rgba(0, 0, 0, 0.45);
}

#ads .containt img {
  width: 30px;
}

#ads .containt h4 {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
  transition: all 0.3s linear;
}

#ads .containt:hover h4 {
  color: var(--highlight-color);
}

#ads .containt p {
  font-size: 16px;
  font-weight: 300;
}

/* ||ADS Css End|| */

/* Price Plans Css Start */
#pricing {
  padding: 50px 0;
}

#pricing .price_heading h2 {
  color: var(--regular-font-color);
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  padding-bottom: 110px;
}

.price_table table {
  width: 80%;
  margin: auto;
  color: var(--regular-font-color);
}

.price_table table thead {
  border-bottom: 2px solid var(--secondary-font-color);
}

.price_table table th {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  padding: 22px 0;
}

.price_table table tr td {
  padding: 23px 0;
}

#pricing .price-buttons {
  text-align: center;
  padding-top: 63px;
}

#pricing .price-buttons button {
  margin: 0 66px;
}

#pricing .price-buttons button a.extra_width {
  padding: 22px 112px;
}

/* ||Price Plans Css End|| */

/* Internet Plans Css Start */
#internet-plans {
  background-color: #000;
}

#internet-plans img {
  width: 100%;
}

#internet-plans .plans_head {
  background: url("/assets/images/banner-2\ .jpg");
  background-position: bottom right !important;
  position: relative;
  z-index: 1;
  padding: 140px 0;
  color: var(--prime-color);
}

#internet-plans .plans_head::after {
  position: absolute;
  content: "";
  background: linear-gradient(
    to right,
    #000000 42%,
    rgba(206, 206, 206, 0.3) 100%
  );
  opacity: 0.9;
  inset: 0;
  z-index: -1;
}

#internet-plans .plans_head h6 {
  font-size: 24px;
  font-weight: 300;
  line-height: 35px;
}

#internet-plans .plans_head h4 {
  font-size: 40px;
  font-weight: 600;
  padding-top: 65px;
}

#internet-plans .plans-container h2 {
  color: var(--prime-color);
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  padding-top: 25px;
  padding-bottom: 119px;
}

#internet-plans .plans-container table {
  width: 100%;
  margin: auto;
  color: var(--prime-color);
}

#internet-plans .plans-container table thead {
  border-bottom: 2px solid var(--secondary-font-color);
}

#internet-plans .plans-container table th {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  padding: 22px 0;
}

#internet-plans .plans-container table tr td {
  padding: 23px 0;
}

#internet-plans .plans-buttons {
  text-align: center;
  padding-top: 63px;
  padding-bottom: 25px;
}

#internet-plans .plans-buttons button {
  margin: 0 66px;
}

#internet-plans .plans-buttons button a.extra_width {
  padding: 22px 112px;
}

#internet-plans .plans-buttons button a {
  border: var(--prime-color) 1px solid;
  transition: var(--first-transition);
}

#internet-plans .plans-buttons button a:hover {
  background: transparent;
}

#internet-plans .plans-container h2.olter {
  padding-bottom: 52px !important;
}

/* ||Internet Plans Css End|| */

/* For remote areas Css Start */
#remote_area {
  background: url("/assets/images/banner-3.png");
  background-size: cover !important;
  background-position: bottom !important;
  position: relative;
  color: var(--prime-color);
  z-index: 1;
  text-align: center;
  padding: 135px 0;
}

#remote_area::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 50%);
  z-index: -1;
}

#remote_area h3 {
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  padding-bottom: 78px;
}

#remote_area p {
  font-size: 20px;
  font-weight: 300;
  line-height: 27px;
}

/* ||For remote areas Css End|| */

/* Internet Satellite Price Plans Css Start */
#satellite-plans {
  padding: 45px 0;
}

#satellite-plans .set_plans {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-content: space-around;
}

#satellite-plans .set-plan-item {
  border: 2px solid #d4d4d4;
  padding: 25px 15px;
  border-radius: 20px;
}

#satellite-plans .set-plan-item h5 {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 12px;
  color: var(--regular-font-color);
}

#satellite-plans ul {
  list-style-type: square !important;
}

#satellite-plans .set-plan-item ul li {
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  color: var(--secondary-font-color);
}

#satellite-plans .set_plans_btn {
  text-align: center;
  padding-top: 68px;
}

/* ||Internet Satellite Price Plans Css End|| */

/* Get In Touch Css Start */
#get-touch {
  background: #cee4ea;
  padding: 58px 0;
  color: var(--prime-color);
}

#get-touch .touch-text h6 {
  font-size: 25px;
  font-weight: 600;
  padding-bottom: 42px;
}

#get-touch .touch-text p {
  font-size: 25px;
  font-weight: 400;
  line-height: 33px;
}

/* ||Get In Touch Css End|| */

/* Download btn Css Start */
.download_btn {
  padding: 30px 0;
  text-align: center;
}

/* ||Download btn Css End|| */

/* Footer Css Start */
footer {
  background: var(--highlight-color);
  padding-top: 207px;
  padding-bottom: 71px;
  color: var(--prime-color);
}

footer a {
  color: var(--prime-color);
  transition: var(--first-transition);
  font-size: 18px;
}

footer a:hover {
  color: var(--prime-color);
  font-size: 20px;
}

footer .footer_logo img {
  width: 100px !important;
}

footer .footer_icon {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  padding-top: 18px;
}

footer .footer-links h6 {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
}

footer .footer-links li {
  display: block;
}

footer .footer-links li a {
  font-size: 18px;
  font-weight: 200;
  line-height: 24px;
}

footer .footer-links li a:hover {
  color: var(--prime-color);
  padding-left: 4px;
  font-weight: 300;
}

footer .footer_map {
  text-align: right;
}

footer .footer_map iframe {
  width: 173px;
  height: 136px;
  border: 3px solid var(--regular-font-color);
}

/* ||Footer Css End|| */

/* ===================
SMME Page
====================== */
header.smme {
  background: url("/assets/images/business-internet-connectivity-solutions-lte-and-sim.png");
}

header.enterprse #banner p {
  padding-top: 30px;
}

header.enterprse #banner p.not_padding {
  padding-top: 0px;
}

/* Internet LTE plans Css Start */
#ltp-plans {
  padding: 50px 0 85px 0;
  border-bottom: 1px solid var(--secondary-font-color);
}

#ltp-plans .ltp_heading h2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 44px;
  padding-bottom: 85px;
  color: var(--regular-font-color);
}

#ltp-plans .ltp_heading h2 span {
  font-weight: 300;
}

#ltp-plans .ltp_plans {
  padding: 50px 20px 0 20px;
  border: 2px solid var(--regular-font-color);
  border-radius: 21px;
  text-align: center;
}

#ltp-plans .ltp_plans h3 {
  font-size: 22px;
  font-weight: 600;
  line-height: 50px;
  padding-bottom: 14px;
}

#ltp-plans .ltp_plans p {
  font-size: 15px;
  font-weight: 300;
  line-height: 35px;
}

#ltp-plans .ltp_plans .ltp_plans_btn {
  padding-top: 25px;
  margin-bottom: -35px;
}

#ltp-plans .ltp_plans .ltp_plans_btn a {
  background: linear-gradient(to bottom, #28cdff 0%, #356ce2 100%);
  border-radius: 17px;
  display: inline-block;
  color: var(--prime-color);
  padding: 20px 22px;
  font-size: 19px;
  font-weight: 500;
  text-transform: uppercase;
  box-shadow: -1px 2px 11px 0px rgba(0, 0, 0, 0.35);
  transition: all 0.3s linear;
}

#ltp-plans .ltp_plans .ltp_plans_btn a i {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background: var(--prime-color);
  color: var(--regular-font-color);
  border-radius: 50%;
  box-shadow: -1px 2px 11px 0px rgba(0, 0, 0, 0.55);
}

#ltp-plans .ltp_plans .ltp_plans_btn a:hover {
  box-shadow: -1px 2px 11px 0px rgba(0, 0, 0, 0.75);
}

.lte_footer p {
  font-size: 16px;
  font-weight: 200;
  padding: 21px 0;
}

/* ||Internet LTE plans Css End|| */

/* LTE Data Plans Css Start */
#ltp-plans.lte-data-plans {
  background-color: #000;
  color: var(--prime-color);
  border: none;
}

#ltp-plans.lte-data-plans .ltp_heading h2 {
  color: var(--prime-color);
}

#ltp-plans.lte-data-plans .ltp_plans {
  padding: 35px 0 0 0;
}

#ltp-plans.lte-data-plans .ltp_plans h5 {
  font-size: 29px;
  font-weight: 600;
}

#ltp-plans.lte-data-plans .ltp_plans h6 {
  font-size: 22px;
  font-weight: 300;
  padding: 10px 0;
}

#ltp-plans.lte-data-plans .ltp_plans .offer {
  background: var(--prime-color);
  color: var(--secondary-font-color);
}

#ltp-plans.lte-data-plans .ltp_plans .offer p {
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  padding: 25px 0;
}

#ltp-plans.lte-data-plans .ltp_plans p {
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  padding: 8px 0;
  border-bottom: 2px solid var(--secondary-font-color);
}

#ltp-plans.lte-data-plans .ltp_plans .plan-price p {
  border-bottom: none;
}

#ltp-plans.lte-data-plans .ltp_plans h4 {
  font-weight: 600px;
  font-size: 70px;
  padding: 0;
  margin: 0;
}

#ltp-plans.lte-data-plans .ltp_plans .ltp_plans_btn a:hover {
  box-shadow: -1px 2px 11px 0px rgba(255, 255, 255, 0.35);
}

/* ||LTE Data Plans Css End|| */

/* View More Css Start */

#view {
  background: url("/assets/images/banner-4.png");
  background-position: bottom right !important;
  position: relative;
  z-index: 1;
  padding: 118px 0;
  color: var(--prime-color);
}

#view::after {
  position: absolute;
  content: "";
  background: linear-gradient(
    to right,
    #000000 60%,
    rgba(206, 206, 206, 0.3) 100%
  );
  inset: 0;
  z-index: -1;
}

#view h4 {
  font-size: 40px;
  font-weight: 600;
}

/* ||View More Css End|| */

/* Price Table Css Start */
#price-table {
  background: #000;
  padding-top: 85px;
}

#price-table .plans_img .overlay {
  color: var(--prime-color);
  margin-top: -100px;
  text-align: center;
  padding-bottom: 30px;
}

#price-table .plans-container {
  padding: 78px 0;
}

/* ||Price Table Css End|| */

/* =================
Privacy Policy Page
=================== */
header.privacy-header {
  height: 400px;
  background: url("/assets/images/terms-and-conditions.png");
  position: relative;
}

header.privacy-header .overlay-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(to bottom, #28cdff 0%, #356ce2 100%);
  padding: 15px 0;
  width: 380px;
  text-align: center;
  color: var(--prime-color);
  clip-path: polygon(7% 0%, 93% 0%, 100% 100%, 0% 100%);
}

header.privacy-header .overlay-btn a {
  color: var(--prime-color);
  transition: var(--first-transition);
}

header.privacy-header .overlay-btn a:hover {
  color: red;
}

#banner.psivacy-banner {
  bottom: 100px;
}

#privacy-containt {
  padding: 100px 0;
}

#privacy-containt .container {
  display: grid;
  gap: 30px;
}

#privacy-containt h5 {
  font-size: 30px;
  font-weight: 400;
  padding-bottom: 20px;
  color: var(--highlight-color);
  text-align: justify;
}

#privacy-containt h6 {
  font-size: 24px;
  font-weight: 400;
  padding-bottom: 10px;
  color: var(--highlight-color);
}

#privacy-containt p {
  font-size: 18px;
  font-weight: 300;
  color: var(--regular-font-color);
  text-align: justify;
  line-height: 30px;
}

#privacy-containt a {
  font-size: 18px;
  color: var(--highlight-color);
}

#privacy-containt .privacy-item ul {
  padding: 12px 0;
}

#privacy-containt .privacy-item ul li {
  display: block;
  font-size: 18px;
  font-weight: 300;
  color: var(--regular-font-color);
  text-align: justify;
  line-height: 30px;
}

#privacy-containt .privacy-item .item-con {
  padding-bottom: 15px;
}

/* =================
FAQ Page
=================== */
#faq {
  padding: 100px 0;
}

#faq .accordion-item {
  border: 2px solid #b3b3b3;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
}

/* =================
Blog Page
=================== */
#blog {
  padding: 100px 0;
}

#blog .blog-content {
  border: 1px solid #b3b3b3;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 25px;
  transition: var(--first-transition);
}

#blog .blog-content:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
}

#blog .blog-content .blog-img {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
}

#blog .blog-content img {
  width: 100%;
}

#blog .blog-content .blog-img .blog-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 70%);
  display: grid;
  align-items: center;
  justify-content: center;
  transition: var(--slow-transition);
  margin-top: -500px;
}

#blog .blog-content:hover .blog-img .blog-overlay {
  margin-top: 0;
}

#blog .blog-content .blog-img .blog-overlay img {
  width: 110px;
}

#blog .blog-content .blog-text {
  text-align: center;
  padding: 15px 20px;
}

#blog .blog-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 15px 0;
}

#blog .blog-content .blog-text h3 {
  font-size: 22px;
  font-weight: 400;
  color: var(--highlight-color);
}

#blog .blog-content .blog-text p {
  font-size: 15px;
  font-weight: 300;
  color: var(--secondary-font-color);
}

#blog .blog-content .blog-text .blog-btn a {
  display: inline-block;
  padding: 10px 20px;
  border: var(--highlight-color) 2px solid;
  border-radius: 6px;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  transition: var(--first-transition);
  text-transform: uppercase;
  z-index: 1;
}

#blog .blog-content .blog-text .blog-btn a::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 0;
  left: 0;
  top: 0;
  background: linear-gradient(to top, #28cdff 0%, #356ce2 100%);
  transition: var(--first-transition);
  z-index: -1;
}

#blog .blog-content .blog-text .blog-btn a:hover {
  color: var(--prime-color);
}

#blog .blog-content .blog-text .blog-btn a:hover::before {
  width: 100%;
}

#blog .next-page button {
  width: 100%;
}

#blog .next-page button a {
  padding: 14px;
  border-radius: 8px;
}

/* =================
Blog Details Page
=================== */
#blog-details {
  padding-top: 100px;
}

#blog-details .blog-details-img img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 25px;
}

#blog-details .blog-details-head h6 {
  color: var(--highlight-color);
  font-size: 16px;
  font-weight: 400;
}

#blog-details .blog-details-head h4 {
  color: var(--regular-font-color);
  font-size: 28px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 15px;
}

#blog-details .blog-details-preview {
  background: #f3f3f3;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  font-size: 18px;
  margin-bottom: 25px;
}

#blog-details .blog-details-preview > p {
  padding: 0;
}

#blog-details p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: justify;
  color: var(--regular-font-color);
  padding-bottom: 20px;
}

#blog-details p span {
  font-size: 24px;
  font-weight: 500;
}

#blog-comment {
  padding-bottom: 100px;
}

#blog-comment .comment-heading h5 {
  background: #f3f3f3;
  border: 2px solid #f3f3f3;
  color: var(--regular-font-color);
  font-size: 20px;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 8px 8px 0 0;
}

#blog-comment .comments {
  border: 2px solid #f3f3f3;
  border-radius: 0 0 8px 8px;
  padding: 20px;
}

.comments .com-item {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.comments .com-item i {
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border: 1px solid var(--regular-font-color);
  color: var(--regular-font-color);
  border-radius: 50%;
  font-size: 30px;
}

.comments .com-item h6 {
  font-size: 18px;
  font-weight: 500;
  color: var(--regular-font-color);
  padding-bottom: 8px;
}

.comments .com-item p {
  font-size: 16px;
  font-weight: 400;
  color: var(--secondary-font-color);
  max-width: 700px;
}

.comment-box input {
  display: block;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #c7c7c7;
  border-radius: 7px;
}

.comment-box textarea {
  display: block;
  width: 100%;
  height: 120px;
  padding: 10px 20px;
  border: 1px solid #c7c7c7;
  border-radius: 7px;
  resize: none;
  margin-top: 10px;
}

.comment-box button {
  width: 100%;
  margin-top: 15px;
  text-align: center;
}

.comment-box button a {
  display: inline-block;
  padding: 10px 20px;
  text-transform: capitalize;
  font-size: 18px;
  border-radius: 7px;
}

/* =================
About Us Page
=================== */
#about-us {
  padding: 100px 0;
}

#about-us .about {
  display: flex;
  align-items: center;
  gap: 20px;
}

#about-us .about .about-text {
  width: 60%;
}

#about-us .about .about-img {
  width: 40%;
}

#about-us .about .about-img .a-img {
  clip-path: polygon(
    30% 0%,
    70% 0%,
    100% 30%,
    100% 70%,
    70% 100%,
    30% 100%,
    0% 70%,
    0% 30%
  );
  overflow: hidden;
}

#about-us .about .about-img .a-img img {
  width: 100%;
  border: 4px solid var(--highlight-color);
  padding: 8px;
}

#about-us .about .about-text h6 {
  color: var(--highlight-color);
  font-size: 26px;
  font-weight: 500;
  padding-bottom: 20px;
}

#about-us .about .about-text p {
  color: var(--regular-font-color);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  max-width: 600px;
}

/* Partners Css Start */
#partners {
  padding-bottom: 100px;
}

#partners h2 {
  font-size: 35px;
  font-weight: 600;
  color: var(--highlight-color);
  padding-bottom: 60px;
}

#partners .partners-container {
  position: relative;
}

#partners .partners-container .partners-item {
  padding: 20px 0 100px 0;
}

#partners .partner-img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fdfdfd;
  box-shadow: -2px 2px 22px 0px rgba(0, 0, 0, 0.1);
  display: grid;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

#partners .partner-img img {
  width: 120px;
}

#partners .partner-img img.bgg {
  background: var(--highlight-color);
  padding: 8px;
  border-radius: 5px;
}

#partners .partner-img.part-mt {
  margin-top: 150px;
}

#partners .partners-container .slider_dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

#partners .partners-container .slider_dots li {
  margin: 0 5px;
}

#partners .partners-container .slider_dots li button {
  font-size: 0;
  width: 30px;
  height: 3px;
  cursor: pointer;
  transition: all 0.3s linear;
  margin-top: 7px;
  background: #b3b3b3;
  border-radius: 10px;
}

#partners .partners-container .slider_dots li.slick-active button {
  background-color: var(--highlight-color);
}

/* ||Partners Css End|| */
